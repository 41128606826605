
        Component.define('share', (el, params) => {
            /**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */

$window
    .on('scroll', function(){
        if(wW > 1250) {
            if($('[data-component="share"]').length) {
                const menuShare = $('[data-component="share"]');
                const offsetShare = 440;
                const scrollPos = $window.scrollTop();

                if (scrollPos > offsetShare) {
                    menuShare.addClass('fixed');
                } else {
                    menuShare.removeClass('fixed');
                }
            }
        }
    });

$('.btn-facebook', el).on('click', function(e) {
    e.preventDefault();

    Share.facebook(window.location.href);
});

$('.btn-linkedin', el).on('click', function(e) {
    e.preventDefault();

    Share.linkedin(window.location.href + "?share=true", $('title').text(), '');
});

$('.btn-twitter', el).on('click', function(e) {
    e.preventDefault();

    Share.twitter(window.location.href, '');
});

$('.btn-whatsapp', el).on('click', function(e) {
    e.preventDefault();

    Share.whatsapp(window.location.href);
});

$('.btn-email', el).on('click', function(e) {
    e.preventDefault();
    var $this = $(this);

    $.dialog({
        closeIcon: true,
        backgroundDismiss: true,
        animateFromElement: false,
        smoothContent: false,
        useBootstrap: false,
        draggable: false,
        title: __('Compartilhar por e-mail'),
        content: '<form class="box-form box-form-share-email"><label class="field-size-2"><span>'+__('Nome')+'</span><input type="text" name="name" class="field-form" required></label><label class="field-size-2"><span>'+__('E-mail do destinatário')+'</span><input type="email" name="email" class="field-form" required></label><div id="share-email-recaptcha"></div><button type="submit" class="button" style="float:right">'+__('Enviar')+'</button></form>',
        onOpen: function(){
            if($('body').attr('data-share-email-recaptcha')){
                grecaptcha.render("share-email-recaptcha", {
                    sitekey: '6LdXk00UAAAAALkK1VIvXPD5M3scB5h1A_QT80mL',
                    callback: function () {
                        console.log('recaptcha callback');
                    }
                });
            }

            $('.box-form-share-email').validate({
                errorElement: 'span',
                errorClass: 'error',
                errorPlacement: function(error, element) {
                },
                submitHandler: function( form ){

                    let $form = $('.box-form-share-email'),
                        name = $form.find('[name="name"]').val(),
                        email = $form.find('[name="email"]').val(),
                        id = $this.attr('data-article-id'),
                        lang = $this.attr('data-article-lang'),
                        recaptcha = $form.find('[name="g-recaptcha-response"]').val(),
                        showMessage = function(data){
                            if(data.error){
                                $form.find('input').prop('disabled', false);
                                $form.find('.button').text(__('Enviar'));
                                $form.removeClass('sending');

                                console.log(data.errors[0]["g-recaptcha-response"]);

                                if(data.count > 1){
                                    $('body').attr('data-share-email-recaptcha', true);

                                    if($('#share-email-recaptcha > div').length === 0){
                                        grecaptcha.render("share-email-recaptcha", {
                                            sitekey: '6LdXk00UAAAAALkK1VIvXPD5M3scB5h1A_QT80mL'
                                        });
                                    } else {
                                        $('.error-recaptcha').remove();
                                        $('[name="g-recaptcha-response"]').after('<span class="error error-recaptcha">'+data.errors[0]["g-recaptcha-response"]+'</span>');
                                    }
                                }
                            } else {
                                $('.box-form-share-email').before('<div data-component="alert" style="display: none;"><div class="alert alert-info"><span><i><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-icon-success"></use></svg></i>'+__('E-mail enviado com sucesso.')+'</span></div></div>');

                                setTimeout(function(){
                                    $('.box-form-share-email').fadeOut(function(){
                                        $('.box-form-share-email')[0].reset();
                                        $('.box-form-share-email').parent().find('[data-component="alert"]').fadeIn();
                                    });
                                }, 500);

                                if(data.count > 1){
                                    $('body').attr('data-share-email-recaptcha', true);
                                }
                            }
                        };

                    console.log(recaptcha);

                    $form.find('input').prop('disabled', true);
                    $form.find('.button').text(__('Enviando...'));

                    if($form.hasClass('sending')){
                        return false;
                    }

                    $form.addClass('sending');

                    $.ajax({
                        url: 'ws/share',
                        type: 'POST',
                        data: { type: 'email', name: name, email: email, id: id, lang: lang, 'g-recaptcha-response': recaptcha }
                    }).done(function(data) {
                        showMessage(data);
                    }).fail(function(data){
                        showMessage(data);
                    });
                    return false;
                }
            });
        }
    });
});

$('.btn-bookmark', el).on('click', function(e) {
    e.preventDefault();

    const $btn = $(this);
    const article_id = $btn.attr('data-article-id');

    $btn.addClass('disabled');

    $.post('ws/bookmark', { article_id: article_id }, function(res) {
        if (!res.error) {
            $btn.removeClass('disabled');
            $btn.toggleClass('active');
        }
    }, 'json');
});

        });