
        Component.define('gallery-slider', (el, params) => {
            /**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */

const slider       = $('.slider-gallery-news', el).get(0);
const btnPrev      = $('.container-slider-gallery-news .slider-arrow-prev', el).get(0);
const btnNext      = $('.container-slider-gallery-news .slider-arrow-next', el).get(0);

const mySwiper = new Swiper(slider, {
    // Optional parameters
    speed: 600,
    spaceBetween: 18,
    slidesPerView: 4,
    navigation: {
        prevEl: btnPrev,
        nextEl: btnNext,
    },
    breakpoints: {
        767: {
            spaceBetween: 9,
            slidesPerView: 3,
        },
        479: {
            spaceBetween: 9,
            slidesPerView: 2,
        }
    },
});

$('.container-slider-gallery-news .slider-item, .gallery-featured figure', el).on('click', function(){
    const $this  = $(this);
    const index  = $this.index() || 0;

    $('[data-component="gallery-modal"]', el).trigger('open', [index]);
});

        });