// jQuery Mask Plugin v1.14.14
// github.com/igorescobar/jQuery-Mask-Plugin
var $jscomp={scope:{},findInternal:function(a,g,d){a instanceof String&&(a=String(a));for(var n=a.length,h=0;h<n;h++){var b=a[h];if(g.call(d,b,h,a))return{i:h,v:b}}return{i:-1,v:void 0}}};$jscomp.defineProperty="function"==typeof Object.defineProperties?Object.defineProperty:function(a,g,d){if(d.get||d.set)throw new TypeError("ES3 does not support getters and setters.");a!=Array.prototype&&a!=Object.prototype&&(a[g]=d.value)};
$jscomp.getGlobal=function(a){return"undefined"!=typeof window&&window===a?a:"undefined"!=typeof global&&null!=global?global:a};$jscomp.global=$jscomp.getGlobal(this);$jscomp.polyfill=function(a,g,d,n){if(g){d=$jscomp.global;a=a.split(".");for(n=0;n<a.length-1;n++){var h=a[n];h in d||(d[h]={});d=d[h]}a=a[a.length-1];n=d[a];g=g(n);g!=n&&null!=g&&$jscomp.defineProperty(d,a,{configurable:!0,writable:!0,value:g})}};
$jscomp.polyfill("Array.prototype.find",function(a){return a?a:function(a,d){return $jscomp.findInternal(this,a,d).v}},"es6-impl","es3");
(function(a,g,d){"function"===typeof define&&define.amd?define(["jquery"],a):"object"===typeof exports?module.exports=a(require("jquery")):a(g||d)})(function(a){var g=function(b,f,e){var c={invalid:[],getCaret:function(){try{var a,q=0,B=b.get(0),f=document.selection,e=B.selectionStart;if(f&&-1===navigator.appVersion.indexOf("MSIE 10"))a=f.createRange(),a.moveStart("character",-c.val().length),q=a.text.length;else if(e||"0"===e)q=e;return q}catch(D){}},setCaret:function(a){try{if(b.is(":focus")){var c,
p=b.get(0);p.setSelectionRange?p.setSelectionRange(a,a):(c=p.createTextRange(),c.collapse(!0),c.moveEnd("character",a),c.moveStart("character",a),c.select())}}catch(C){}},events:function(){b.on("keydown.mask",function(a){b.data("mask-keycode",a.keyCode||a.which);b.data("mask-previus-value",b.val());b.data("mask-previus-caret-pos",c.getCaret());c.maskDigitPosMapOld=c.maskDigitPosMap}).on(a.jMaskGlobals.useInput?"input.mask":"keyup.mask",c.behaviour).on("paste.mask drop.mask",function(){setTimeout(function(){b.keydown().keyup()},
100)}).on("change.mask",function(){b.data("changed",!0)}).on("blur.mask",function(){d===c.val()||b.data("changed")||b.trigger("change");b.data("changed",!1)}).on("blur.mask",function(){d=c.val()}).on("focus.mask",function(b){!0===e.selectOnFocus&&a(b.target).select()}).on("focusout.mask",function(){e.clearIfNotMatch&&!h.test(c.val())&&c.val("")})},getRegexMask:function(){for(var a=[],b,c,e,k,d=0;d<f.length;d++)(b=m.translation[f.charAt(d)])?(c=b.pattern.toString().replace(/.{1}$|^.{1}/g,""),e=b.optional,
(b=b.recursive)?(a.push(f.charAt(d)),k={digit:f.charAt(d),pattern:c}):a.push(e||b?c+"?":c)):a.push(f.charAt(d).replace(/[-\/\\^$*+?.()|[\]{}]/g,"\\$&"));a=a.join("");k&&(a=a.replace(new RegExp("("+k.digit+"(.*"+k.digit+")?)"),"($1)?").replace(new RegExp(k.digit,"g"),k.pattern));return new RegExp(a)},destroyEvents:function(){b.off("input keydown keyup paste drop blur focusout ".split(" ").join(".mask "))},val:function(a){var c=b.is("input")?"val":"text";if(0<arguments.length){if(b[c]()!==a)b[c](a);
c=b}else c=b[c]();return c},calculateCaretPosition:function(){var a=b.data("mask-previus-value")||"",f=c.getMasked(),e=c.getCaret();if(a!==f){var d=b.data("mask-previus-caret-pos")||0,f=f.length,k=a.length,m=a=0,h=0,g=0,l;for(l=e;l<f&&c.maskDigitPosMap[l];l++)m++;for(l=e-1;0<=l&&c.maskDigitPosMap[l];l--)a++;for(l=e-1;0<=l;l--)c.maskDigitPosMap[l]&&h++;for(l=d-1;0<=l;l--)c.maskDigitPosMapOld[l]&&g++;e>k?e=10*f:d>=e&&d!==k?c.maskDigitPosMapOld[e]||(d=e,e=e-(g-h)-a,c.maskDigitPosMap[e]&&(e=d)):e>d&&
(e=e+(h-g)+m)}return e},behaviour:function(e){e=e||window.event;c.invalid=[];var f=b.data("mask-keycode");if(-1===a.inArray(f,m.byPassKeys))return f=c.getMasked(),setTimeout(function(){c.setCaret(c.calculateCaretPosition())},a.jMaskGlobals.keyStrokeCompensation),c.val(f),c.callbacks(e)},getMasked:function(a,b){var d=[],p=void 0===b?c.val():b+"",k=0,h=f.length,g=0,q=p.length,l=1,n="push",t=-1,w=0,y=[],u,z;e.reverse?(n="unshift",l=-1,u=0,k=h-1,g=q-1,z=function(){return-1<k&&-1<g}):(u=h-1,z=function(){return k<
h&&g<q});for(var A;z();){var x=f.charAt(k),v=p.charAt(g),r=m.translation[x];if(r)v.match(r.pattern)?(d[n](v),r.recursive&&(-1===t?t=k:k===u&&k!==t&&(k=t-l),u===t&&(k-=l)),k+=l):v===A?(w--,A=void 0):r.optional?(k+=l,g-=l):r.fallback?(d[n](r.fallback),k+=l,g-=l):c.invalid.push({p:g,v:v,e:r.pattern}),g+=l;else{if(!a)d[n](x);v===x?(y.push(g),g+=l):(A=x,y.push(g+w),w++);k+=l}}p=f.charAt(u);h!==q+1||m.translation[p]||d.push(p);d=d.join("");c.mapMaskdigitPositions(d,y,q);return d},mapMaskdigitPositions:function(a,
b,f){a=e.reverse?a.length-f:0;c.maskDigitPosMap={};for(f=0;f<b.length;f++)c.maskDigitPosMap[b[f]+a]=1},callbacks:function(a){var g=c.val(),h=g!==d,m=[g,a,b,e],k=function(a,b,c){"function"===typeof e[a]&&b&&e[a].apply(this,c)};k("onChange",!0===h,m);k("onKeyPress",!0===h,m);k("onComplete",g.length===f.length,m);k("onInvalid",0<c.invalid.length,[g,a,b,c.invalid,e])}};b=a(b);var m=this,d=c.val(),h;f="function"===typeof f?f(c.val(),void 0,b,e):f;m.mask=f;m.options=e;m.remove=function(){var a=c.getCaret();
m.options.placeholder&&b.removeAttr("placeholder");b.data("mask-maxlength")&&b.removeAttr("maxlength");c.destroyEvents();c.val(m.getCleanVal());c.setCaret(a);return b};m.getCleanVal=function(){return c.getMasked(!0)};m.getMaskedVal=function(a){return c.getMasked(!1,a)};m.init=function(d){d=d||!1;e=e||{};m.clearIfNotMatch=a.jMaskGlobals.clearIfNotMatch;m.byPassKeys=a.jMaskGlobals.byPassKeys;m.translation=a.extend({},a.jMaskGlobals.translation,e.translation);m=a.extend(!0,{},m,e);h=c.getRegexMask();
if(d)c.events(),c.val(c.getMasked());else{e.placeholder&&b.attr("placeholder",e.placeholder);b.data("mask")&&b.attr("autocomplete","off");d=0;for(var g=!0;d<f.length;d++){var n=m.translation[f.charAt(d)];if(n&&n.recursive){g=!1;break}}g&&b.attr("maxlength",f.length).data("mask-maxlength",!0);c.destroyEvents();c.events();d=c.getCaret();c.val(c.getMasked());c.setCaret(d)}};m.init(!b.is("input"))};a.maskWatchers={};var d=function(){var b=a(this),f={},e=b.attr("data-mask");b.attr("data-mask-reverse")&&
(f.reverse=!0);b.attr("data-mask-clearifnotmatch")&&(f.clearIfNotMatch=!0);"true"===b.attr("data-mask-selectonfocus")&&(f.selectOnFocus=!0);if(n(b,e,f))return b.data("mask",new g(this,e,f))},n=function(b,f,e){e=e||{};var c=a(b).data("mask"),d=JSON.stringify;b=a(b).val()||a(b).text();try{return"function"===typeof f&&(f=f(b)),"object"!==typeof c||d(c.options)!==d(e)||c.mask!==f}catch(w){}},h=function(a){var b=document.createElement("div"),e;a="on"+a;e=a in b;e||(b.setAttribute(a,"return;"),e="function"===
typeof b[a]);return e};a.fn.mask=function(b,d){d=d||{};var e=this.selector,c=a.jMaskGlobals,f=c.watchInterval,c=d.watchInputs||c.watchInputs,h=function(){if(n(this,b,d))return a(this).data("mask",new g(this,b,d))};a(this).each(h);e&&""!==e&&c&&(clearInterval(a.maskWatchers[e]),a.maskWatchers[e]=setInterval(function(){a(document).find(e).each(h)},f));return this};a.fn.masked=function(a){return this.data("mask").getMaskedVal(a)};a.fn.unmask=function(){clearInterval(a.maskWatchers[this.selector]);delete a.maskWatchers[this.selector];
return this.each(function(){var b=a(this).data("mask");b&&b.remove().removeData("mask")})};a.fn.cleanVal=function(){return this.data("mask").getCleanVal()};a.applyDataMask=function(b){b=b||a.jMaskGlobals.maskElements;(b instanceof a?b:a(b)).filter(a.jMaskGlobals.dataMaskAttr).each(d)};h={maskElements:"input,td,span,div",dataMaskAttr:"*[data-mask]",dataMask:!0,watchInterval:300,watchInputs:!0,keyStrokeCompensation:10,useInput:!/Chrome\/[2-4][0-9]|SamsungBrowser/.test(window.navigator.userAgent)&&h("input"),
watchDataMask:!1,byPassKeys:[9,16,17,18,36,37,38,39,40,91],translation:{0:{pattern:/\d/},9:{pattern:/\d/,optional:!0},"#":{pattern:/\d/,recursive:!0},A:{pattern:/[a-zA-Z0-9]/},S:{pattern:/[a-zA-Z]/}}};a.jMaskGlobals=a.jMaskGlobals||{};h=a.jMaskGlobals=a.extend(!0,{},h,a.jMaskGlobals);h.dataMask&&a.applyDataMask();setInterval(function(){a.jMaskGlobals.watchDataMask&&a.applyDataMask()},h.watchInterval)},window.jQuery,window.Zepto);
