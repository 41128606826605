
        Component.define('header', (el, params) => {
            /**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */

$window.on('resize', function() {
    const $menu         = $('.menu-primary');
    const $items        = $menu.find('> li:visible > a');
    const menuWidth     = $menu.width();
    const itensCount    = $items.length;
    let sumW            = 0;

    $items.css('padding', '0');

    if (dW >= 1024) {
        $items.each(function() {
            sumW += $(this).width();
        });

        /**
         * Para descontar o padding-left do primeiro item e o padding-right do último item (pois ambos elemnentos devem estar encostados no .content)
         * utilizei no cálculo (itensCount - 1)
         */
        let padding = Math.floor((menuWidth - sumW) / (itensCount - 1) / 2);

        $items.css('padding', `0 ${padding}px`);
        $items.eq(0).css('padding', `0 ${padding}px 0 0`);
        $items.eq(itensCount - 1).css('padding', `0 0 0 ${padding}px`);
    }
});

$('.menu-primary > li > a')
    .on('click', function () {
        if (wW < 1024) {
            const $this = $(this);
            const $nav  = $this.parents('.menu-primary');

            if ($this.attr('href').match(/^\bjavascript:void\b/)) {
                $this.parent().addClass('is-active');
                $nav.addClass('sub-opened');
            }
        }
    });

$('.menu-primary .sub h2').on('click', function(){
    if (wW < 1024) {
        const $this = $(this);

        $this.next().slideToggle(400);
    }
});

$('.menu-primary .back-menu').on('click', function(){
    if (wW < 1024) {
        const $this = $(this);
        const $nav  = $this.parents('.menu-primary');

        $nav.removeClass('sub-opened').find('.is-active').removeClass('is-active');
    }
});

$('.hamburger').on('click', function() {
    $('.nav-secondary .menu-secondary').toggleClass('is-active');
    $('.nav-primary').toggleClass('is-active');
    $('body, html').toggleClass('overflow-hidden');
});

$('.menu-primary > li > a:eq(5)').trigger('click');
$('.menu-primary > li > a:eq(5) ~ .sub h2:eq(0)').trigger('click');

        });