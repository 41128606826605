
        Component.define('card', (el, params) => {
            /**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */
if ($(el).parents('.my-itens').length) {
    $(el).parents('.card-item').find('.btn-close').on('click', function(e) {
        e.preventDefault();

        const $btn       = $(this);
        const $item      = $btn.parents('.card-item');
        const article_id = $btn.attr('data-article-id');

        $btn.addClass('disabled');

        $.confirm({
            closeIcon: true,
            backgroundDismiss: true,
            animateFromElement: false,
            smoothContent: false,
            useBootstrap: false,
            draggable: false,
            title: __('Você tem certeza?'),
            content: __('Você realmente deseja remover este item?'),
            buttons: {
                confirm: {
                    text: __('Confirmar'),
                    btnClass: 'button',
                    action: function() {
                        $.post('ws/bookmark', { article_id: article_id }, function(res) {
                            if (!res.error) {
                                $btn.removeClass('disabled');
                                $item.fadeOut(400, function() {
                                    $(this).remove();
                                    $('.card-mosaic').masonry('layout');
                                });
                            }
                        }, 'json');
                    }
                },
                cancel: {
                    text: __('Cancelar'),
                    btnClass: 'button',
                    action: function() {
                        $btn.removeClass('disabled');
                    }
                }
            }
        });
    });
}

        });