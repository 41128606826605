function __(v) {
    if(i18n){
        if(i18n[v]){
            return i18n[v];
        } else {
            return v;
        }
    } else {
        return v;
    }

}
