
        Component.define('gallery-modal', (el, params) => {
            /**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */

/**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */

const btnPrevModal = $('.slider-arrow-prev', el).get(0);
const btnNextModal = $('.slider-arrow-next', el).get(0);

const sliderThumb = $('.slider-gallery-thumb', el).get(0);
const sliderZoom  = $('.slider-gallery-zoom', el).get(0);

window._wq = window._wq || [];

const mySwiperZoom = new Swiper(sliderZoom, {
    // Optional parameters
    speed: 600,
    spaceBetween: 56,
    slidesPerView: 1,
    navigation: {
        prevEl: btnPrevModal,
        nextEl: btnNextModal,
    },
    keyboard: {
        enabled: true,
        onlyInViewport: true,
    },
    on: {
        transitionStart: function () {
            _wq.push({ id: "_all", onReady: function(video) {
                video.pause();
            }});
        },
    },
});

const mySwiperThumb = new Swiper(sliderThumb, {
    speed: 600,
    spaceBetween: 18,
    centeredSlides: true,
    slidesPerView: 'auto',
    slideToClickedSlide: true,
    breakpoints: {
        767: {
            spaceBetween: 9,
        },
        479: {
            spaceBetween: 5,
        }
    },
});

mySwiperThumb.controller.control = mySwiperZoom;
mySwiperZoom.controller.control = mySwiperThumb;

$('.close-modal', el).on('click', function(){
    TweenMax.to(el, .4, {opacity: 0, display: 'none', onComplete: function() {
        $body.removeClass('overflow-hidden');
    }});

    _wq.push({ id: "_all", onReady: function(video) {
        video.pause();
    }});
});

$(el).on('open', function (e, index) {
    _wq.push({ id: "_all", onReady: function(video) {
        video.pause();
    }});

    $body.addClass('overflow-hidden');

    TweenMax.set(el, { opacity: 0, display: 'block' });

    mySwiperThumb.update();
    mySwiperZoom.update();
    mySwiperZoom.slideTo(index, 0);

    TweenMax.to(el, .4, { opacity: 1 });
});

$('.slider-gallery-zoom, .slider-gallery-thumb', el).on('click', function (e) {
    if (!($(e.target).is('img') || $(e.target).is('.slider-arrow') || $(e.target).closest('.slider-gallery-thumb .swiper-wrapper').length)) {
        $('.close-modal', el).trigger('click');
    }
});

$document.on('keydown', function(e) {
     if (e.keyCode == 27) {
        $('.close-modal', el).trigger('click');
    }
});

        });