/**
* Main code
*/

'use strict';

let $window     = $(window);
let $document   = $(document);
let $body       = $('body');
let wW          = $window.width();
let wH          = $window.height();
let dW          = $document.width();
let dH          = $document.height();

if (Is.mobile.ANY()) {
    $body.addClass('mobile');
} else {
    $body.addClass('desktop');
}

if (Is.desktop.MAC()) {
    $body.addClass('mac');
}

$document.on('preloaded.after', function () {
    $window.trigger('scroll');
});

$document.on('preloaded.before', function () {
    $window
        .on('scroll', function () {
            if (!Is.mobile.ANY()) {
                Animator.onScroll();
            }
        })
        .on('resize', function () {
            wW = $window.width();
            wH = $window.height();
        });

    Component.init();

    $('.card-mosaic').isotope({
        itemSelector: '.card-item',
    });

    if ($('.image-bank .card-album').length) {
        $('.image-bank .card-album').matchHeight();
    }

    $('[name="country"]').on('change', function() {
        const $this  = $(this);
        const $state = $('[name="state"]');
        const $city  = $('[name="city"]');
        const $group = $('.group-fields-brazil');

        const val = $this.val();

        if (val === 'BRA') {
            $group.show();
        } else {
            $state.val('');
            $city.val('');
            $group.hide();
        }
    }).trigger('change');

    $('.card-album-photo .album-link').on('click', function() {
        const $this  = $(this);
        const index  = $this.parents('.card-album-photo').index() || 0;

        $('[data-component="gallery-modal"]').trigger('open', [index]);
    });

    $window.on('resize', function () {
        $('.card-album img').each(function () {
            const $this = $(this);
            const $container = $this.parents('.container-card-album');

            $container.width('100%');
            $container.height('100%');

            $container.width($this.width());
            $container.height($this.height());
        });
    });

    $('[name=change-password]').on('change', function(e) {
        e.preventDefault();
        var $this = $(this);
        var $password_fields = $(document).find('.password-fields');
        if($this.is(':checked')) {
            $password_fields.find('input').val('');
            $password_fields.removeClass('hide');
        } else {
            $password_fields.addClass('hide');
        }
    });

    $window.trigger('resize');
});

$body.waitForImages(true)
    .progress((loaded, count, success) => {
        count++;

        let percent = (loaded * 100 / count);

        TweenMax.to('.preloader__bar__progress', .6, { width: percent+'%', ease: Expo.easeInOut });
    })
    .done(() => {
        TweenMax.to('.preloader__bar__progress', .6, { width: '100%', onComplete: () => {
            $document.trigger('preloaded.before');

            TweenMax.to('.preloader', .4, { opacity: 0, display: 'none', onComplete: () => $document.trigger('preloaded.after') });
        } });
    });
