
        Component.define('search-form', (el, params) => {
            /**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */

$label = $('.pull-left', el);
$document.on('click', function(e) {
    if ($(e.target).closest($label).length === 0) {
        $('.box-autocomplete').hide();
    }
});

const config = {
    opens: 'left',
    autoUpdateInput: false,
    locale: {
        format: 'DD/MM/YY',
        separator: ' - ',
        applyLabel: __('Aplicar'),
        cancelLabel: __('Remover'),
        fromLabel: __('De'),
        toLabel: __('Até'),
        daysOfWeek: [
            __('D'),
            __('S'),
            __('T'),
            __('Q'),
            __('Q'),
            __('S'),
            __('S'),
        ],
        monthNames: [
            __('Janeiro'),
            __('Fevereiro'),
            __('Março'),
            __('Abril'),
            __('Maio'),
            __('Junho'),
            __('Julho'),
            __('Agosto'),
            __('Setembro'),
            __('Outubro'),
            __('Novembro'),
            __('Dezembro'),
        ],
        firstDay: 1
    },
    maxDate: new Date(),
    showCustomRangeLabel: false,
    buttonClasses: 'button',
    cancelClass: 'button-gray',
    icons: {
        calendar: '<i class="icon-calendar"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-icon-calendar"></use></svg></i>',
        arrowLeft: '<i class="icon-arrow-left"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-icon-arrow-left"></use></svg></i>',
        arrowRight: '<i class="icon-arrow-right"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-icon-arrow-right"></use></svg></i>',
    },
};

$('.field-period', el)
    .daterangepicker(config)
    .on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YY') + ' - ' + picker.endDate.format('DD/MM/YY'));
        $('form', el).submit();
    })
    .on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
        $('form', el).submit();
    })
    .on('showCalendar.daterangepicker', function(ev, picker) {
        if(wW < 768) {
            $('body, html').addClass('overflow-hidden');
        }
    });

$document.on('click', '.daterangepicker .btn-close', function(){
    $('.field-period', el).data('daterangepicker').hide();
    $('body, html').removeClass('overflow-hidden');
});

$document.on('click', '.btn-open-calendar', function(){
    $('.field-period', el).data('daterangepicker').show();
});

let globalTimeout = null;

$('.field-search').on('keyup', function(){
    let $this = $(this);
    let q = $this.val();
    let date = $('[name="date"]').val();

    if (globalTimeout != null) {
        clearTimeout(globalTimeout);
    }
    globalTimeout = setTimeout(function() {
        globalTimeout = null;
        if($this.val().length > 1){
            $.ajax({
                method: "GET",
                url: $this.closest('form').attr('action'),
                data: { q: q, date: date },
                dataType: 'html'
            }).done(function (response) {
                $('.box-autocomplete').empty();
                if(response === '0'){
                    $('.box-autocomplete').hide();
                } else {
                    $('.box-autocomplete').append(response);
                    $('.box-autocomplete').show();
                }

            });
        }
    }, 500);
});

        });