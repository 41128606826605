
        Component.define('pagination', (el, params) => {
            /**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */

$('select', el).on('change', function () {
    const get = queryStringToObject(window.location.search);
    get.p = this.value;

    window.location.href = window.location.pathname + '?' + objectToQueryString(get) ;
});

function queryStringToObject(str) {
    const strArr = String(str).replace(/^\?/, '').replace(/^&/, '').replace(/&$/, '').split('&');

    let obj = {};

    $.each(strArr, (i, v) => {
        const parts    = v.split('=');

        if (parts.length == 2) {
            obj[parts[0]] = decodeURI(parts[1]);
        }
    });

    return obj;
}

function objectToQueryString(obj) {
    let querystring = [];

    for (let key in obj) {
        querystring.push(`${key}=${encodeURI(obj[key])}`);
    }

    return querystring.join('&');
}

        });