!(function(window, document, undefined){
    'use strict';

    let $window     = $(window);
    let $document   = $(document);

    $.scrollDepth({
        userTiming: false,
        pixelDepth: false,
        nonInteraction: false
    });
})(window, document, undefined);
