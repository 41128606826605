
        Component.define('hamburger', (el, params) => {
            /**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */

$('.hamburger').on('click', function() {
    let $this  = $(this);

    $this.toggleClass('is-active');
});

        });