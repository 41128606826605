
        Component.define('slider', (el, params) => {
            /**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */

/*$('.slider-featured').slick({
    arrows: false,
    dots: true,
    appendDots: $('.box-slick-dots')
});*/

const slider = $('.slider-featured', el).get(0);
const pagination = $('.swiper-pagination', el).get(0);

let mySwiper;

function createSwiper() {
    if (!mySwiper || mySwiper.destroyed) {
        mySwiper = new Swiper(slider, {
            // Optional parameters
            speed: 600,
            loop: true,
            spaceBetween: 20,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            pagination: {
                el: pagination,
                clickable: true,
            }
        });
    }
}

$(slider)
    .on('mouseenter', function(){
        mySwiper.autoplay.stop();
    })
    .on('mouseleave', function(){
        mySwiper.autoplay.start();
    });

$(window).on('resize', function () {
    if ($(window).width() < 480) {
        if (mySwiper && !mySwiper.destroyed) {
            mySwiper.destroy();
        }
    } else {
        createSwiper();
    }
});

        });