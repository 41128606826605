
        Component.define('gallery-mosaic', (el, params) => {
            /**
 * Each component receives the variables explained below
 *
 * @param {HTMLElement} el - Component's main DOM element
 * @param {Object} params - Params to be used
 */

$('.card-photo', el).on('click', function(){
    const $this  = $(this);
    const index  = $this.index() || 0;

    $('[data-component="gallery-modal"]', el).trigger('open', [index]);
});

        });